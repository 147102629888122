<template>
  <block title="最热门场馆">
    <el-radio-group v-model="type" slot="actions">
      <el-radio label="展会数量">展会数量</el-radio>
      <el-radio label="展会总面积">展会总面积</el-radio>
    </el-radio-group>
    <v-chart class="chart" :option="option"/>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ChangguanHotStatBlock",
  components: {
    VChart,
    Block
  },
  data(){
    return {
      type:'展会数量',
      data:[],
    }
  },
  created() {
    this.init()
  },
  computed:{
    option(){
      return {
        tooltip: {
          trigger: "item",
        },
        grid:{
          bottom:90
        },
        xAxis: {
          type: 'category',
          data: this.data.map(i=>i.mc),
          axisLabel:{
            rotate:30
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: this.data.map(i=>i.value),
            type: 'bar',
            label:{
              show:true,
              position:'top',
            }
          }
        ]
      }
    },
  },
  watch:{
    type(){
      this.init()
    }
  },
  methods:{
    async init(){
      if(this.type==='展会数量'){
        this.data=(await api.statChangguanByZhanhuiCount(15)).data
      }else{
        this.data=(await api.statChangguanByZhanhuiMj(15)).data
      }
    },
  }
}
</script>

<style scoped>
.chart {
  height: 380px;
}
</style>
