<template>
  <block title="周边酒店">
    <div class="hxe-hotel-cards">
      <div v-for="item in data" :key="item.data.id" class="hxe-hotel-card">
        <img class="hxe-hotel-card-logo" :src="item.data.image_list&&item.data.image_list.length>0?item.data.image_list[0]:''">
        <a class="hxe-hotel-card-title" :title="item.data.name"
           :href="$router.resolve({path:'/hxe-changguan/details',query:{id:item.data.id}}).href">{{ item.data.name }}</a>
        <div class="hxe-hotel-card-subtitle">{{ item.data.city_name }} · {{ item.data.county_name }}</div>
        <div class="hxe-hotel-card-type">{{ item.data.type }}</div>
        <div class="hxe-hotel-card-field">最大会场：{{ item.data.conf_max_space }}m²</div>
        <div class="hxe-hotel-card-distance">距离：{{ item.distance.toFixed(0) }}米</div>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "ChangguanNearestHotelListBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  props: {
    changguan: Object
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const params = {
        type:['四星酒店','三星酒店','五星酒店','三星以下'],
        geoLon: this.changguan.geoLocation.lon,
        geoLat: this.changguan.geoLocation.lat,
        geoDistance: 3000,
        page: 0,
        size: 5,
      }
      const data = (await api.listHuixiaoerChangguan(params)).data.content
      this.data=data
    }
  }
}
</script>

<style scoped>

</style>
