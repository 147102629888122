<template>
  <block title="场馆办展情况">
    <div slot="actions">
      <el-select v-model="area" clearable placeholder="全部区域" style="width: 120px;"
                 size="small">
        <el-option v-for="item in areas" :key="item" :value="item" :label="item"></el-option>
      </el-select>
      <el-date-picker size="mini" style="width: 230px;margin-left: 10px;" :clearable="false"
                      v-model="dateRange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期" unlink-panels
                      end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
    </div>
    <div v-if="data">
      <el-table :data="data.content" :default-sort = "sort"
                @sort-change="onSortChange" tooltip-effect="light" class="large-font-table">
        <el-table-column prop="mc" label="场馆" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/changguan/details',query:{id:row.id}}).href">{{row.mc}}</a>
        </el-table-column>
        <el-table-column prop="zhanhuiTotalMj" label="办展总面积（㎡）" width="160" sortable="custom"></el-table-column>
        <el-table-column prop="zhanhuiCount" label="办展次数" width="110" sortable="custom">
          <a class="link" slot-scope="{row}" @click="showZhanhuiList(row)">{{row.zhanhuiCount}}</a>
        </el-table-column>
        <el-table-column prop="hy" label="行业" width="250">
          <ellipsis-text slot-scope="{row}" :text="row.hy"></ellipsis-text>
        </el-table-column>
        <el-table-column label="最新会展情况" align="center">
          <el-table-column prop="latestZhanhui.zhmc" label="会展名称" show-overflow-tooltip>
            <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/zhanhui/details',query:{id:row.latestZhanhui.id}}).href">{{row.latestZhanhui.zhmc}}</a>
          </el-table-column>
          <el-table-column label="展期" width="240">
            <span slot-scope="{row}">{{row.latestZhanhui.jbkssj}} ~ {{row.latestZhanhui.jbjssj}}</span>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
    <zhanhui-list-dialog ref="zhanhuiListDialog"></zhanhui-list-dialog>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import moment from "moment";
import ZhanhuiListDialog from "./ZhanhuiListDialog";
import EllipsisText from "./EllipsisText";

export default {
  name: "ChangguanZhanhuiInfoBlock",
  components: {Block,ZhanhuiListDialog,EllipsisText},
  props:{
    title:String
  },
  data() {
    return {
      areas: ['华东', '华南', '西南', '华北', '西北', '华中', '东北'],
      area:null,
      dateRange:[moment().subtract(1,"year").toDate(),moment().toDate()],
      pickerOptions: {
        shortcuts: [{
          text: '最近一个月',
          onClick(picker) {
            picker.$emit('pick', [moment().subtract(1,"months"), moment().toDate()]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            picker.$emit('pick', [moment().subtract(3,"months"), moment().toDate()]);

          }
        },
          {
            text: '最近半年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(6,"months"), moment().toDate()]);

            }
          },
          {
            text: '最近一年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(1,"years"), moment().toDate()]);
            }
          }]
      },
      sort:{prop: 'zhanhuiTotalMj', order: 'descending'},
      data: null
    }
  },
  watch:{
    area(){
      this.init()
    },
    dateRange(){
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        area:this.area,
        startDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[0]).format('YYYY-MM-DD'):null,
        endDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[1]).format('YYYY-MM-DD'):null,
        page:page-1,
        size:10,
        sort:this.sort.prop+","+(this.sort.order==='descending'?'DESC':'ASC')
      }
      this.data=(await api.listChangguanZhanhuiInfo(params)).data
    },
    onSortChange({prop, order}){
      this.sort={prop, order}
      this.loadData(1)
    },
    showZhanhuiList(row){
      const params={
        jbcg:row.mc,
        startDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[0]).format('YYYY-MM-DD'):null,
        endDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[1]).format('YYYY-MM-DD'):null,
      }
      const title=[row.mc,'会展情况']
      if(this.dateRange&&this.dateRange.length>0){
        title.push('（',moment(this.dateRange[0]).format('YYYY-MM-DD'),' ~ ',
            moment(this.dateRange[1]).format('YYYY-MM-DD'),'）')
      }
      this.$refs.zhanhuiListDialog.open(title.join(''),params)
    }
  }
}
</script>

<style scoped>

</style>
