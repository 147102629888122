<template>
  <block title="重要会展">
    <el-carousel indicator-position="outside" height="235px">
      <el-carousel-item v-for="(slice,index) in data" :key="index">
        <div class="zhanhui-cards">
          <a class="zhanhui-card" v-for="zhanhui in slice" :key="zhanhui.id"
             :href="$router.resolve({path:'/zhanhui/details',query:{id:zhanhui.id}}).href">
            <img class="zhanhui-card-logo" v-attachment-attr:src="zhanhui.logo">
            <div class="zhanhui-card-name v-center" :title="zhanhui.zhmc">
              <span>{{zhanhui.zhmc.length>25?(zhanhui.zhmc.substring(0,25)+'...'):zhanhui.zhmc}}</span>
            </div>
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "ChangguanZhanhuiListBlock",
  components:{Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  props:{
    changguan:Object
  },
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      const params = {
        state:'END',
        jbcg: this.changguan.mc,
        page: 0,
        size: 20,
        sort: 'jbkssj,DESC'
      }
      const data=(await api.listZhanhui(params)).data.content
      const sliceSize=4
      const sliceCount=Math.ceil(data.length/sliceSize)
      const slices=[]
      for(let i=0;i<sliceCount;i++){
        slices.push(data.slice(i*sliceSize,(i+1)*sliceSize))
      }
      this.data=slices
    }
  }
}
</script>

<style scoped>

</style>
