<template>
  <block title="场馆面积统计">
    <el-row>
      <el-col :span="8">
        <v-chart class="chart" :option="option"/>
      </el-col>
      <el-col v-for="list in lists" :key="list.stat.name" :span="8">
        <h3>{{list.stat.name}}（{{list.stat.value}}家）</h3>
        <div class="info-list">
          <div v-for="item in list.data" :key="item.id" class="info-list-item">
            <div class="info-list-field">
              <a class="link" :href="$router.resolve({path:'/changguan/details',query:{id:item.id}}).href">{{ item.mc }}</a>
            </div>
            <div class="info-list-field info-list-field-fixed" style="width: 100px;">{{item.mj}}㎡</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ChangguanMjStatBlock",
  components: {
    VChart,
    Block
  },
  data(){
    return {
      data:[],
      lists:[]
    }
  },
  created() {
    this.init()
  },
  computed:{
    option(){
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '场馆面积统计',
            type: 'pie',
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
            },
            data: this.data
          }
        ]
      }
    },
  },
  methods:{
    async init(){
      this.data=(await api.getChangguanMjStat()).data.filter(i=>i.name)
      for(const item of this.data.slice(0,2)){
        const params = {page:0,size:10}
        if(item.name==='2万以下'){
          params.maxMj=20000
        }else if(item.name==='2万到3万'){
          params.minMj=20000
          params.maxMj=30000
        }else if(item.name==='3万到4万'){
          params.minMj=30000
          params.maxMj=40000
        }else if(item.name==='4万到5万'){
          params.minMj=40000
          params.maxMj=50000
        }else if(item.name==='5万以上'){
          params.minMj=50000
        }
        this.lists.push({
          stat:item,
          data:(await  api.listChangguan(params)).data.content
        })
      }
    },
  }
}
</script>

<style scoped>
.chart {
  height: 380px;
}
</style>
