<template>
  <block title="空置期分析">
    <el-date-picker slot="actions" size="mini" style="width: 230px" :clearable="false"
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期" unlink-panels
        end-placeholder="结束日期" :picker-options="pickerOptions">
    </el-date-picker>
    <el-row>
      <el-col :span="16">
        <v-chart class="chart" :option="option"/>
        <div>注：单一场馆空置率=无会展天数/选定时间区间天数</div>
      </el-col>
      <el-col :span="8">
        <div class="info-list">
          <div v-for="item in changguanData" :key="item.id" class="info-list-item">
            <div class="info-list-field">
              <a class="link" :href="$router.resolve({path:'/changguan/details',query:{id:item.id}}).href">{{ item.mc }}</a>
            </div>
            <div class="info-list-field info-list-field-fixed" style="width: 65px">{{ (item.kzl*100).toFixed(2) }}%</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ChangguanKzqStatBlock",
  components: {
    VChart,
    Block
  },
  data(){
    return {
      dateRange:[moment().subtract(1,"year").toDate(),moment().toDate()],
      areaData:[],
      changguanData:[],
      pickerOptions: {
        shortcuts: [{
          text: '最近一个月',
          onClick(picker) {
            picker.$emit('pick', [moment().subtract(1,"months"), moment().toDate()]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            picker.$emit('pick', [moment().subtract(3,"months"), moment().toDate()]);

          }
        },
          {
            text: '最近半年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(6,"months"), moment().toDate()]);

            }
          },
          {
            text: '最近一年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(1,"years"), moment().toDate()]);
            }
          }]
      },
    }
  },
  created() {
    this.init()
  },
  computed:{
    option(){
      const topTen = this.areaData.slice(0,10)
      return {
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: 'category',
          data: topTen.map(i=>i.area),
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: topTen.map(i=>(i.kzl*100).toFixed(2)),
            type: 'bar',
            barMaxWidth:40,
            label:{
              show:true,
              position:'top',
              formatter: '{c}%'
            }
          }
        ]
      }
    },
  },
  watch:{
    dateRange(){
      this.init()
    }
  },
  methods:{
    async init(){
      this.areaData=(await api.listAreaKzl({
        startDate:moment(this.dateRange[0]).format('YYYY-MM-DD'),
        endDate:moment(this.dateRange[1]).format('YYYY-MM-DD')
      })).data.filter(i=>i.area)
      this.changguanData=(await api.listChangguanKzl({
        startDate:moment(this.dateRange[0]).format('YYYY-MM-DD'),
        endDate:moment(this.dateRange[1]).format('YYYY-MM-DD'),
        limit:10
      })).data
    },
  }
}
</script>

<style scoped>
.chart {
  height: 380px;
}
</style>
