<template>
  <block title="最新场馆资讯">
    <a slot="actions" class="link-button"><img src="../assets/images/dots.png"></a>
    <div class="info-list">
      <div v-for="item in data" :key="item.id" class="info-list-item">
        <div class="info-list-item-dot"></div>
        <div class="info-list-field">
          <a class="link" :href="item.url">{{item.title}}</a>
        </div>
        <div class="info-list-field info-list-field-fixed" style="width: 100px;">{{item.time.substring(0,10)}}</div>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ChangguanNewsBlock",
  components:{Block},
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      this.data=(await api.listChangguanNews({page:0,size:10})).data.content
    }
  }
}
</script>

<style scoped>

</style>
