<template>
  <block title="地点探查">
    <el-row :gutter="20">
      <el-col :span="10">
        <p class="text">{{changguan?changguan.jj.substring(0,200):zhanhui.jbcg}}...</p>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="标记附近酒店"></el-checkbox>
        </el-checkbox-group>
      </el-col>
      <el-col :span="14">
        <div id="container"></div>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import Block from "./Block";
import AMapLoader from '@amap/amap-jsapi-loader';
import api from "../api";

window._AMapSecurityConfig = {
  securityJsCode: '166aab4f4b5d52dbc5ead115ae1f7126',
}

export default {
  name: "ChangguanDdtcBlock",
  components: {Block},
  props: {
    changguan: Object
  },
  data() {
    return {
      checkList:[]
    }
  },
  watch:{
    checkList(){
      if(this.checkList.indexOf('标记附近酒店')!==-1){
        this.addHotelMarkers()
      }else{
        this.removeHotelMarkers()
      }
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  beforeDestroy() {
    this.map.destroy();
  },
  methods: {
    async addHotelMarkers(){
      if(this.hotelMarkers){
        return
      }
      const params={
        type:['四星酒店','三星酒店','五星酒店','三星以下'],
        geoLon:this.changguan.geoLocation.lon,
        geoLat:this.changguan.geoLocation.lat,
        geoDistance:3000,
        page:0,
        size:100
      }
      const hotels=(await api.listHuixiaoerChangguan(params)).data.content
      this.hotelMarkers=[]
      for(const hotel of hotels){
        const marker = new this.AMap.Marker({
          position: new this.AMap.LngLat(hotel.location2.lon, hotel.location2.lat),
          title: hotel.data.name
        });
        this.hotelMarkers.push(marker)
        marker.on('click', e=>{
          const clickedMarker=e.target
          const infoWindow = new this.AMap.InfoWindow({
            content: clickedMarker.getTitle(),
            offset:[0,-30]
          });
          infoWindow.open(this.map,[clickedMarker.getPosition().getLng(),clickedMarker.getPosition().getLat()]);
        });
      }
      this.map.add(this.hotelMarkers);
    },
    async removeHotelMarkers(){
      if(!this.hotelMarkers){
        return
      }
      this.map.remove(this.hotelMarkers);
      this.hotelMarkers=null
    },
    initMap() {
      AMapLoader.load({
        key: "0ebc613ce16f5d7acfa059d9439a6128",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.PlaceSearch', 'AMap.Marker', 'AMap.InfoWindow'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.AMap=AMap
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "2D",    //是否为3D地图模式
          zoom: 14,  //初始化地图层级
          center: [this.changguan.geoLocation.lon, this.changguan.geoLocation.lat]  //初始化地图中心点,
        });
        this.toolbar = new AMap.ToolBar(); // 缩放工具条实例化
        this.map.addControl(this.toolbar);
        this.scale = new AMap.Scale(); // 比例尺实例化
        this.map.addControl(this.scale);

        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.changguan.geoLocation.lon, this.changguan.geoLocation.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.changguan.mc
        });
        this.map.add(marker);
        marker.on('click', e=>{
          const clickedMarker=e.target
          const infoWindow = new AMap.InfoWindow({
            content: clickedMarker.getTitle(),
            offset:[0,-30]
          });
          infoWindow.open(this.map,[clickedMarker.getPosition().getLng(),clickedMarker.getPosition().getLat()]);
        });

        // this.placeSearch = new AMap.PlaceSearch({
        //   pageSize: 1, // 单页显示结果条数
        //   pageIndex: 1, // 页码
        //   map: this.map, // 展现结果的地图实例
        //   autoFitView: false
        // });
        //
        // this.placeSearch.search(this.changguan.mc,(status, result) => {
        //   // 查询成功时，result即对应匹配的POI信息
        //   console.log(status,result)
        //   this.map.setZoomAndCenter(15, result.poiList.pois[0].location);
        // });

      }).catch(e => {
        console.log(e);
      })
    },
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 350px;
}
</style>
