<template>
  <block title="发展分析">
    <el-row>
      <el-col :span="12">
        <v-chart class="chart" :option="barOption" @click="onClick"/>
      </el-col>
      <el-col :span="12">
        <v-chart class="chart" :option="mapOption" @click="onClick"/>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px;">
      <el-col :span="22" :offset="1" style="display: flex;align-items: flex-start;">
        <el-button size="mini" style="margin-right: 20px;" @click="play">自动播放</el-button>
        <el-steps :active="year-startYear" finish-status="success" style="flex: auto">
          <el-step v-for="i in years" :key="i" :title="i+''"></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <el-button v-if="province" style="position: absolute;top:10px;right:10px" type="primary" @click="back">返回
    </el-button>
  </block>
</template>

<script>
import api from "../api";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, MapChart} from "echarts/charts";
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent, VisualMapComponent} from "echarts/components";
import VChart from "vue-echarts";
import '../echarts/map'
import Block from "./Block";

use([
  CanvasRenderer,
  BarChart,
  MapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent
]);

export default {
  name: "ChangguanAreaStatBlock",
  components: {
    VChart,
    Block
  },
  data() {
    const endYear = new Date().getFullYear()
    const startYear = endYear - 9
    const years = []
    for (let year = startYear; year <= endYear; year++) {
      years.push(year)
    }
    return {
      startYear,
      endYear,
      year: endYear,
      years: years,
      provinces: [],
      province: null,
      cities: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
    barOption() {
      if (!this.province) {
        const topTen = this.provinces.slice(0, 10)
        return {
          tooltip: {
            trigger: "item",
          },
          xAxis: {
            type: 'category',
            data: topTen.map(i => i.name),
            axisLabel: {
              rotate: 45
            }
          },
          yAxis: {
            type: 'value',
            name: '数量（个）'
          },
          series: [
            {
              data: topTen.map(i => i.value),
              type: 'bar',
              label: {
                show: true,
                position: 'top'
              },
              barMaxWidth:'40'
            }
          ]
        }
      } else {
        const topTen = this.cities.slice(0, 10)
        return {
          xAxis: {
            type: 'category',
            data: topTen.map(i => i.name),
            axisLabel: {
              rotate: 45
            }
          },
          yAxis: {
            type: 'value',
            name: '数量（个）'
          },
          series: [
            {
              data: topTen.map(i => i.value),
              type: 'bar',
              label: {
                show: true,
                position: 'top'
              },
              barMaxWidth:'40'
            }
          ]
        }
      }
    },
    mapOption() {
      if (!this.province) {
        return {
          title: [
            {
              textStyle: {
                color: "#000",
                fontSize: 18
              },
              subtext: "",
              text: '中国',
              top: "auto",
              subtextStyle: {
                color: "#aaa",
                fontSize: 12
              },
              left: "auto"
            }
          ],
          visualMap: {
            min: 0,
            max: this.provinces.reduce((s, v) => {
              return v.value > s ? v.value : s
            }, 0),
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['#FDEDED', '#F00B0D']
            }
          },
          series: [
            {
              map: 'china',
              data: this.provinces,
              name: "",
              type: "map",
              label: {
                show: true
              }
            }
          ]
        }
      } else {
        return {
          title: [
            {
              textStyle: {
                color: "#000",
                fontSize: 18
              },
              subtext: "",
              text: this.province,
              top: "auto",
              subtextStyle: {
                color: "#aaa",
                fontSize: 12
              },
              left: "auto"
            }
          ],
          visualMap: {
            min: 0,
            max: this.cities.reduce((s, v) => {
              return v.value > s ? v.value : s
            }, 0),
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['#FDEDED', '#F00B0D']
            }
          },
          series: [
            {
              map: this.province,
              data: this.cities,
              name: "",
              type: "map",
              label: {
                show: true
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    async init() {
      this.provinces = (await api.getChangguanProvinceStat(this.year)).data.filter(i => i.name)
    },
    onClick(evt) {
      if (this.province) {
        return
      }
      this.drillToProvince(evt.name)
    },
    async drillToProvince(province) {
      this.province = province
      this.cities = (await api.getChangguanCityStat(this.year, province)).data
    },
    async back() {
      this.province = null
    },
    async reloadData() {
      if (this.province) {
        this.cities = (await api.getChangguanCityStat(this.year, this.province)).data
      } else {
        this.provinces = (await api.getChangguanProvinceStat(this.year)).data.filter(i => i.name)
      }
    },
    async play() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.year = this.startYear
      await this.reloadData()
      const playNextFrame=async ()=>{
        this.year++
        await this.reloadData()
        if(this.year<this.endYear){
          setTimeout(playNextFrame, 1000)
        }
      }
      setTimeout(playNextFrame, 1000)
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
