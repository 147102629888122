<template>
  <block :title="title">
    <a slot="actions" class="link-button"><img src="../assets/images/dots.png"></a>
    <div class="info-list">
      <div v-for="item in data" :key="item.id" class="info-list-item">
        <div class="info-list-item-dot"></div>
        <div class="info-list-field">
          <a class="link" :href="$router.resolve({path:'/changguan/details',query:{id:item.id}}).href">{{ item.mc }}</a>
        </div>
        <div class="info-list-field info-list-field-fixed" style="width: 60px">{{ item.province }}</div>
        <div class="info-list-field info-list-field-fixed" style="width: 100px;" v-if="title==='最新开业场馆'">{{ item.kysj }}</div>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ChangguanListBlock",
  components: {Block},
  props:{
    title:String
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const params = {
        page: 0,
        size: 10,
      }
      if(this.title==='最新开业场馆'){
        params.sort='kysj,DESC'
      }else{
        params.sort='id,DESC'
      }
      this.data = (await api.listChangguan(params)).data.content
    }
  }
}
</script>

<style scoped>

</style>
