<template>
  <block title="场馆分布统计">
    <el-radio-group slot="actions" size="mini" v-model="type">
      <el-radio label="count">场馆数量</el-radio>
      <el-radio label="mj">场馆面积</el-radio>
    </el-radio-group>
    <el-row>
      <el-col :span="16">
        <v-chart class="chart" :option="barOption"/>
      </el-col>
      <el-col :span="8">
        <v-chart class="chart" :option="pieOption"/>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {BarChart, PieChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  DataZoomSliderComponent,
  GridComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  DataZoomSliderComponent,
  GridComponent
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ChangguanCountAndMjStatBlock",
  components: {
    VChart,
    Block
  },
  data(){
    return {
      type:'count',
      barData:[],
      pieData:[]
    }
  },
  created() {
    this.init()
  },
  computed:{
    barOption(){
      return {
        grid:{
          left:'12%'
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: 'category',
          data: this.barData.map(i=>i.name),
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          name:this.type==='count'?'数量(个)':'面积（㎡）'
        },
        dataZoom:[
          {
            type:'slider',
            minValueSpan:15,
            maxValueSpan:15,
            zoomLock:true,
            brushSelect:false
          }
        ],
        series: [
          {
            data: this.barData.map(i=>i.value),
            type: 'bar',
            label:{
              show:this.type==='count',
              position:'top',
            }
          }
        ]
      }
    },
    pieOption(){
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '会展面积统计',
            type: 'pie',
            radius: ['30%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              formatter: '{b}: {c}'+(this.type==='count'?'个':'㎡'),
              overflow:'break'
            },
            data: this.pieData
          }
        ]
      }
    },
  },
  watch:{
    type(){
      this.init()
    }
  },
  methods:{
    async init(){
      if(this.type==='count'){
        this.barData=(await api.getChangguanCountStatByCity()).data.filter(i=>i.name)
        this.pieData=(await api.getChangguanCountStatByArea()).data.filter(i=>i.name)
      }else{
        this.barData=(await api.getChangguanMjStatByCity()).data.filter(i=>i.name)
        this.pieData=(await api.getChangguanMjStatByArea()).data.filter(i=>i.name)
      }
    },
  }
}
</script>

<style scoped>
.chart {
  height: 380px;
}
</style>
