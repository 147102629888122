import { render, staticRenderFns } from "./ChangguanListBlock.vue?vue&type=template&id=038ba03e&scoped=true&"
import script from "./ChangguanListBlock.vue?vue&type=script&lang=js&"
export * from "./ChangguanListBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038ba03e",
  null
  
)

export default component.exports