<template>
  <div class="page-content" v-if="data">
    <div class="page-tips">数据来源于E展网</div>
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">办展行业：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <tag-select :tags="industries" v-model="searchForm.hy" multiple></tag-select>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">场馆地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">使用情况：</div>
          <div class="search-form-field-content">
            <tag-select :tags="['已有展会','暂无展会']" v-model="searchForm.usedState"></tag-select>
            <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                            v-model="searchForm.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" size="small" unlink-panels
                            end-placeholder="结束日期" v-if="searchForm.usedState">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">场馆：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.mcLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">举办会展：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zhmcLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">面积：</div>
          <div class="search-form-field-content v-center">
            <el-input-number v-model="searchForm.minMj" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>~</span>
            <el-input-number v-model="searchForm.maxMj" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>㎡</span>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">类型：</div>
          <div class="search-form-field-content v-center">
            <tag-select :tags="['会议中心','会展中心']" v-model="searchForm.type"></tag-select>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 20px;">
      <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="mc" label="场馆" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/changguan/details',query:{id:row.id}}).href">{{row.mc}}</a>
        </el-table-column>
        <el-table-column prop="hy" label="行业" width="420" show-overflow-tooltip></el-table-column>
        <el-table-column prop="kysj" label="开业时间" width="120"></el-table-column>
        <el-table-column prop="mj" label="面积（㎡）" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dz" label="场馆地址" width="300" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import TagSelect from "./TagSelect";
import moment from "moment";
import Collapse from "./Collapse";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "ChangguanList",
  components:{TagSelect,Collapse},
  data(){
    return {
      industries:["机械工业","电子电力","光电技术","交通工具","运输物流","汽摩配件","仪器仪表","暖通制冷","信息通信","安全防护","建材五金","能源矿产","钢铁冶金","纺织纺机","印刷包装","化工橡塑","环保水处理","食品饮料","服装配饰","皮革鞋业","美容美发","房产家居","孕婴童展","旅游行业","消费电子","婚庆婚博","音响乐器","礼品玩具","医疗保健","体育休闲","办公用品","纸业制品","酒店用品","奢侈品展","宠物行业","动漫游戏","购物年货","进出口展","创业加盟","广告媒体","文化教育","文化艺术","农林牧渔",'其它'],
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      searchForm:{
        hy:[],
        usedState:null,
        dateRange:null,
        mcLike:null,
        zhmcLike:null,
        type:null,
        minMj:undefined,
        maxMj:undefined
      },
      searchParams:{
        hy:null,
        province:null,
        usedState:null,
        usedFrom:null,
        usedEnd:null,
        mcLike:null,
        zhmcLike:null,
        type:null,
        minMj:null,
        maxMj:null
      },
      data:null
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      if(this.$route.query.type){
        this.searchForm.type=this.$route.query.type
      }
      if(this.$route.query.usedState){
        this.searchForm.usedState=this.$route.query.usedState
        if(this.$route.query.startDate&&this.$route.query.endDate){
          this.searchForm.dateRange=[
            moment(this.$route.query.startDate,DATE_FMT),
            moment(this.$route.query.endDate,DATE_FMT)
          ]
        }
      }
      if(this.$route.query.area){
        const i=this.areaAndProvinces.find(i=>i.area===this.$route.query.area)
        if(i){
          i.selected=[...i.provinces]
        }
      }
      if(this.$route.query.hy){
        if(Array.isArray(this.$route.query.hy)){
          this.searchForm.hy=this.$route.query.hy
        }else{
          this.searchForm.hy=[this.$route.query.hy]
        }
      }
      await this.search()
    },
    async search(){
      this.searchParams.hy=this.searchForm.hy
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.province=null
      }else{
        this.searchParams.province=province
      }
      if(this.searchForm.usedState){
        this.searchParams.usedState=this.searchForm.usedState==='已有展会'
        if (this.searchForm.dateRange) {
          this.searchParams.usedFrom = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
          this.searchParams.usedEnd = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
        }else{
          this.searchParams.usedFrom = null
          this.searchParams.usedEnd = null
        }
      }else{
        this.searchParams.usedState=null
        this.searchParams.usedFrom = null
        this.searchParams.usedEnd = null
      }
      this.searchParams.type=this.searchForm.type
      this.searchParams.mcLike=this.searchForm.mcLike
      this.searchParams.zhmcLike=this.searchForm.zhmcLike
      this.searchParams.minMj=this.searchForm.minMj
      this.searchParams.maxMj=this.searchForm.maxMj
      await this.loadData(1)
    },
    async reset(){
      this.searchForm.hy=[]
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.usedState=null
      this.searchForm.dateRange=null
      this.searchForm.mcLike=null
      this.searchForm.zhmcLike=null
      this.searchForm.type=null
      this.searchForm.minMj=undefined
      this.searchForm.maxMj=undefined
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        page:page-1,
        size:10,
      }
      this.data=(await api.listChangguan(params)).data
    }
  }
}
</script>

<style scoped>

</style>
