<template>
  <div class="page-content">
    <div class="page-tips">数据来源于E展网</div>
    <el-row style="margin-top: 10px;">
      <el-col :span="24">
        <div class="indicators">
          <div class="indicator" v-for="item in indicators" :key="item.title">
            <div class="indicator-top">
              <span v-if="item.onclick" class="indicator-value indicator-value-link" @click="item.onclick">{{item.value}}</span>
              <span v-else class="indicator-value">{{item.value}}</span>
              <span class="indicator-unit">{{item.unit}}</span>
            </div>
            <div class="indicator-bottom">
              <img :src="item.icon" class="indicator-icon">
              <span class="indicator-title">{{item.title}}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-count-and-mj-stat-block></changguan-count-and-mj-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-zhanhui-info-block></changguan-zhanhui-info-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8">
        <changguan-list-block title="最新开业场馆"></changguan-list-block>
      </el-col>
      <el-col :span="8">
        <changguan-list-block title="在建场馆"></changguan-list-block>
      </el-col>
      <el-col :span="8">
        <changguan-news-block></changguan-news-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-area-stat-block></changguan-area-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-mj-stat-block></changguan-mj-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-kzq-stat-block></changguan-kzq-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-hot-stat-block></changguan-hot-stat-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import hyhz from "../assets/images/hyhz.png";
import pqgl from "../assets/images/pqgl.png";
import ywc from "../assets/images/ywc.png";
import dkmj from "../assets/images/dkmj.png";
import fz from "../assets/images/fz.png";
import sjfw from "../assets/images/sjfw.png";
import ChangguanListBlock from "./ChangguanListBlock";
import ChangguanNewsBlock from "./ChangguanNewsBlock";
import ChangguanAreaStatBlock from "./ChangguanAreaStatBlock";
import ChangguanMjStatBlock from "./ChangguanMjStatBlock";
import ChangguanKzqStatBlock from "./ChangguanKzqStatBlock";
import ChangguanHotStatBlock from "./ChangguanHotStatBlock";
import ChangguanCountAndMjStatBlock from "./ChangguanCountAndMjStatBlock";
import api from "../api";
import ChangguanZhanhuiInfoBlock from "./ChangguanZhanhuiInfoBlock";

export default {
  name: "ChangguanHome",
  components:{
    ChangguanListBlock,
    ChangguanNewsBlock,
    ChangguanAreaStatBlock,
    ChangguanMjStatBlock,
    ChangguanKzqStatBlock,
    ChangguanHotStatBlock,
    ChangguanCountAndMjStatBlock,
    ChangguanZhanhuiInfoBlock
  },
  data(){
    return {
      indicators:[]
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      const data=(await api.getChangguanTotalStat()).data
      this.indicators=[
        {
          title:'所有场馆',
          value:data.total,
          unit:'个',
          icon:hyhz,
          onclick:()=>{
            this.$router.push({path:'/changguan/list'})
          }
        },
        {
          title:'会议中心',
          value:data.totalHyzx,
          unit:'个',
          icon:pqgl,
          onclick:()=>{
            this.$router.push({path:'/changguan/list',query:{type:'会议中心'}})
          }
        },
        {
          title:'会展中心',
          value:data.totalHzzx,
          unit:'个',
          icon:ywc,
          onclick:()=>{
            this.$router.push({path:'/changguan/list',query:{type:'会展中心'}})
          }
        },
        {
          title:'在建场馆',
          value:100,
          unit:'个',
          icon:dkmj
        },
        {
          title:'场馆总面积',
          value:(data.totalMj/10000).toFixed(0),
          unit:'万平方米',
          icon:fz
        },
        {
          title:'会展酒店',
          value:12861,
          unit:'个',
          icon:sjfw
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
